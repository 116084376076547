<template>
    
    <b-container>
        <b-row>
            <b-col md="12" cols="12">
                <b-row>
                    <b-col v-for="(item, index) in news.dataSource.items" :key="index" cols="12" md="4" lg="4">
                        <news-view :item='item' :large='true' />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
    
</template>

<script>
    
    import news from "@/modules/contents/news"
    import NewsView from "@/components/NewsView"
    export default {

        data() {
            return {
                news
            }
        },
        methods: {

        },
        components: {
            NewsView
        },
        watch: {

        },
        mounted() {
//            if( this.news.dataSource.items.length === 0 ) {
//                this.news.get();
//            }
        }

    }

</script>